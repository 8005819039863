import React, { FC } from 'react';
import { ReactComponent as TopWave } from '../../../assets/svgs/homepage/next-steps-top.svg';
import { ReactComponent as BottomWave } from '../../../assets/svgs/homepage/next-steps-bottom.svg';
import { ReactComponent as Hotline } from '../../../assets/svgs/homepage/next-steps-hotline.svg';
import { ButtonLink } from '../../common/Button';
import { MonoFontLabel } from '../../common/typography';
import { SYSTEM_FONTS } from '../../../styles/typography';
import { markdownText } from '../../../pages/doctors';
import { useIntl } from 'react-intl';
import {
  HUBSPOT_MEETING_MANU_LINK,
  REQUEST_MATERIALS_HUBSPOT_FORM_FORM_ID,
  REQUEST_MATERIALS_HUBSPOT_FORM_PORTAL_ID,
  REZEPT_URL,
} from '../../../utils/constants';
import HubspotOverlay from '../../HubspotFormOverlay';
import HubspotMeetingOverlay from '../../HubspotMeetingOverlay';

interface Props {
  eliCheckerLink?: string;
  requestMaterialsFormId?: string;
  requestMaterialsFormPortalId?: string;
  bookAppointmentLink?: string;
  rezeptUrl?: string;
}

export const NextStepsCard = ({
  title,
  description,
  ctaText,
  type,
  requestMaterialsFormId,
  requestMaterialsFormPortalId,
  rezeptUrl,
}: {
  title: string;
  description: string;
  ctaText: string;
  type: string;
  eliCheckerLink?: string;
  requestMaterialsFormId?: string;
  requestMaterialsFormPortalId?: string;
  rezeptUrl?: string;
}) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: '#fff',
      padding: 24,
      borderRadius: 8,
      textAlign: 'center',
    }}
  >
    <p
      css={{
        fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
        fontWeight: 500,
        fontSize: 28,
        color: '#000',
        maxWidth: 400,
      }}
    >
      {markdownText(title)}
    </p>
    <p
      css={{
        fontFamily: ['Hero New'].concat(SYSTEM_FONTS).join(', '),
        fontWeight: 400,
        fontSize: 16,
        color: '#000',
        maxWidth: 400,
      }}
    >
      {description}
    </p>

    {type === 'hubspot-form' && (
      <HubspotOverlay
        formId={
          requestMaterialsFormId
            ? requestMaterialsFormId
            : REQUEST_MATERIALS_HUBSPOT_FORM_FORM_ID
        }
        portalId={
          requestMaterialsFormPortalId
            ? requestMaterialsFormPortalId
            : REQUEST_MATERIALS_HUBSPOT_FORM_PORTAL_ID
        }
        buttonText={ctaText}
        style={{
          color: 'white',
          fontWeight: 700,
          borderColor: '#2AAB3C',
          backgroundColor: '#2AAB3C',
        }}
      />
    )}
    {type === 'rezeptservice' && (
      <ButtonLink
        design="bordered"
        css={{
          color: 'white',
          fontWeight: 700,
          borderColor: '#2AAB3C',
          backgroundColor: '#2AAB3C',
        }}
        to={rezeptUrl ? rezeptUrl : REZEPT_URL}
      >
        {ctaText}
      </ButtonLink>
    )}
  </div>
);

const NextSteps: FC<Props> = ({
  eliCheckerLink,
  requestMaterialsFormId,
  requestMaterialsFormPortalId,
  bookAppointmentLink,
  rezeptUrl,
}) => {
  const intl = useIntl();

  const nextSteps = [
    {
      title: intl.formatMessage({
        id: 'homepage.v2.feature.next.steps.card.one.title',
        defaultMessage: `Vorbereitung auf deinen <b style="color: #235544">Arztbesuch.</b>`,
      }),
      description: intl.formatMessage({
        id: 'homepage.v2.feature.next.steps.card.one.text',
        defaultMessage:
          'Erhalte ein Verschreibungsformular sowie Infomaterial für deinen Arztbesuch.',
      }),
      ctaText: intl.formatMessage({
        id: 'homepage.v2.feature.next.steps.card.one.button',
        defaultMessage: 'Material anfordern',
      }),
      type: 'hubspot-form',
    },
    {
      title: intl.formatMessage({
        id: 'homepage.v2.feature.next.steps.card.two.title',
        defaultMessage:
          '<b style="color: #235544">Rezept einreichen</b> und Freischaltcode erhalten',
      }),
      description: intl.formatMessage({
        id: 'homepage.v2.feature.next.steps.card.two.text',
        defaultMessage:
          'Sende uns einfach dein Rezept zu. Wir kümmern uns für dich um den Rest!',
      }),
      ctaText: intl.formatMessage({
        id: 'homepage.v2.feature.next.steps.card.two.button',
        defaultMessage: 'Rezeptservice',
      }),
      type: 'rezeptservice',
    },
  ];

  return (
    <div css={{ marginBottom: 4, marginTop: '-3rem' }}>
      <TopWave
        width="100%"
        height="25"
        css={{
          display: 'block',
          width: '100%',
          height: 'auto',
          marginTop: -1,
          position: 'relative',
          zIndex: 1,
        }}
      />
      <div
        css={{
          marginTop: -1,
          backgroundColor: '#235544',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Content */}
          <MonoFontLabel
            css={{
              marginTop: 15,
              fontWeight: 700,
              fontSize: 14,
              marginRight: 10,
              textTransform: 'uppercase',
              color: '#fff',
            }}
          >
            {intl.formatMessage({
              id: 'homepage.v2.feature.next.steps.prefix',
              defaultMessage: 'Deine nächsten Schritte',
            })}
          </MonoFontLabel>
          {/* Cards */}
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
              gridGap: 24,
              padding: 24,
              maxWidth: 1050,
              width: '100%',
            }}
          >
            {nextSteps.map((step, i) => (
              <NextStepsCard
                key={i}
                title={step.title}
                description={step.description}
                ctaText={step.ctaText}
                type={step.type}
                eliCheckerLink={eliCheckerLink}
                requestMaterialsFormId={requestMaterialsFormId}
                requestMaterialsFormPortalId={requestMaterialsFormPortalId}
                rezeptUrl={rezeptUrl}
              />
            ))}
          </div>
        </div>
        {/* Hotline */}
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
              flexDirection: 'row',
            }}
          >
            <Hotline />
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 10,
                textAlign: 'center',
              }}
            >
              <p
                css={{
                  fontFamily: ['Blacker Pro Display']
                    .concat(SYSTEM_FONTS)
                    .join(', '),
                  fontWeight: 500,
                  fontSize: 22,
                  color: '#fff',
                  maxWidth: 400,
                  marginBottom: 2,
                }}
              >
                {intl.formatMessage({
                  id: 'homepage.v2.feature.next.steps.cta.title',
                  defaultMessage: 'Weitere Fragen?',
                })}
              </p>
              <p
                css={{
                  fontFamily: ['Hero New'].concat(SYSTEM_FONTS).join(', '),
                  fontWeight: 400,
                  fontSize: 16,
                  color: '#ffffff80',
                  maxWidth: 200,
                  marginBottom: 0,
                }}
              >
                {intl.formatMessage({
                  id: 'homepage.v2.feature.next.steps.cta.text',
                  defaultMessage:
                    'Vereinbare einfach ein Infogespräch mit uns.',
                })}
              </p>
            </div>
          </div>
          <HubspotMeetingOverlay
            meetingLink={
              bookAppointmentLink
                ? bookAppointmentLink
                : HUBSPOT_MEETING_MANU_LINK
            }
            buttonText={intl.formatMessage({
              id: 'homepage.v2.feature.next.steps.cta.button',
              defaultMessage: 'Gespräch Buchen',
            })}
          />
        </div>
      </div>
      <BottomWave
        css={{
          display: 'block',
          width: '100%',
          height: 'auto',
          marginTop: -1,
          position: 'relative',
          zIndex: 1,
        }}
      />
    </div>
  );
};

export default NextSteps;
